import { render, staticRenderFns } from "./range-price2.vue?vue&type=template&id=40f32662&"
import script from "./range-price2.vue?vue&type=script&lang=js&"
export * from "./range-price2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RangeSlider2: require('/home/d/didisher2/avalon-avto.ru/www/components/range/range-slider2.vue').default})
